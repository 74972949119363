/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";
import { adjustElementHeightToFitContainer } from "@components/utilities/dom_helpers";

export class Controller extends BaseController {
  connect() {
    const height_defining_element_selector = this.element.dataset?.heightDefiningElementSelector 
    this.scrollingContainerBottomOffset = this.element.dataset?.scrollingContainerBottomOffset
    this.heightDefiningElement = document.querySelector(height_defining_element_selector)
    this.scrollingContainer = document.querySelector('.prescribe-it-ui--prescribe-it-thread-detail-section .listed-messages-section')
    this.setupResizeableContainer()
  }

  setupResizeableContainer() {
    this.boundadjustElementHeightToFitContainer = () => adjustElementHeightToFitContainer(this.scrollingContainer, this.heightDefiningElement, this.scrollingContainerBottomOffset)    
    this.boundadjustElementHeightToFitContainer()
    window.addEventListener('resize', this.boundadjustElementHeightToFitContainer)
  }

  disconnect() {
    window.removeEventListener('resize', this.boundadjustElementHeightToFitContainer)
  }
}
