import { Controller as BaseController } from "@hotwired/stimulus";
import { adjustElementHeightToFitContainer } from "@components/utilities/dom_helpers";
import { InfiniteScroller } from "../../utilities/infinite_scroller";

export class Controller extends BaseController {
  connect() {
    // Initialize properties
    const scrolling_container_selector = this.element.dataset?.scrollingContainerSelector
    const height_defining_element_selector = this.element.dataset?.heightDefiningElementSelector    
    this.scrollingContainerBottomOffset = this.element.dataset?.scrollingContainerBottomOffset
    this.heightDefiningElement = document.querySelector(height_defining_element_selector)
    this.listContainer = document.querySelector(scrolling_container_selector)
    if (!this.listContainer) {
      throw new Error('PrescribeItThreadList: Element to resize not found')
    }
    this.setupResizeableContainer()
    // Setup infinite scroll
    new InfiniteScroller(this.listContainer, this.element.dataset?.url)
  }

  // Container Setup
  setupResizeableContainer() {
    this.boundadjustElementHeightToFitContainer = () => adjustElementHeightToFitContainer(this.listContainer, this.heightDefiningElement, this.scrollingContainerBottomOffset)    
    this.boundadjustElementHeightToFitContainer()
    window.addEventListener('resize', this.boundadjustElementHeightToFitContainer)
  }

  toggleAllCheckboxes(event) {
    const el = this.element.querySelector('#prescribe-it-thread-list')
    const checkboxes = el.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach(checkbox => checkbox.checked = event.target.checked)
  }

  filterList(event) {
    const url = new URL(event.target.dataset.url)
    url.searchParams.set('filter', event.target.value)
    
    Turbo.visit(url.toString(), { frame: 'prescribe-it-thread-list-container' })
  }

  // Cleanup
  disconnect() {
    window.removeEventListener('resize', this.boundadjustElementHeightToFitContainer)
    this.intersectionObserver?.disconnect()
  }
}