function isHTML(str) {
  const parser = new DOMParser();
  // Parse the string as HTML
  const doc = parser.parseFromString(str.replace(/<</g, '"').replace(/>>/g, '"'), 'text/html');
  // Check for parsing errors
  if (doc.querySelector('parsererror')) {
    return false;
  } else {
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }
}

export function wrapInTagIfNotHTML(str) {
  // convert into html if not already
  const result = isHTML(str) ? str : convertStringIntoHtml(str);
  // escape << and >> characters because dynamic macros use them
  return result.replace(/<</g, '&lt;&lt;').replace(/>>/g, '&gt;&gt;');
}

function convertStringIntoHtml(str) {
  if (str) {
    // delete \r
    str = str.replace("\r", "");
    // split by \n
    const substrings = str.split('\n');
    // join with p tags
    return substrings.map((s) => `<p>${s}</p>`).join("")
  } else {
    return str;
  }
}

export function toggleDisplay(el, status) {
  if (['none', ''].includes(el.style.display) && status == true) {
    el.style.display = "block";
  } else if (status == false) {
    el.style.display = "none";
  }
}

export function toggleDisabled(el, status) {
  if (el.hasAttribute("disabled") && status == false) {
    el.removeAttribute("disabled");
  } else if (status == true) {
    el.setAttribute("disabled", "");
  }
}


export function adjustElementHeightToFitContainer(elementToResize, heightDefiningElement, bottomPadding = 20) {
  if (!elementToResize) {
    throw new Error('adjustElementHeightToFitContainer: elements to resize are not defined')
  }
  
  
  const elementToResizeHeightTop = elementToResize.getBoundingClientRect().top
  const heightDefiningElementBottom = heightDefiningElement.getBoundingClientRect().bottom  

  const availableHeight =  heightDefiningElementBottom - elementToResizeHeightTop
  
  elementToResize.style.maxHeight = `${availableHeight - bottomPadding}px`
  elementToResize.style.overflow = 'auto'
}