/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";
// import RequestController from "@components/utilities/request_controller"

export class Controller extends BaseController {
  connect() {
    // const requester = new RequestController;
    // requester.fetchTurboStream({ params: { url: '/task_view/tasks/task_menu_index' } })
  }

  disconnect() {
  }
}
